<template>
  <v-container class="p-0">
    <v-card>
      <v-card-title class="primary--text pt-1 pb-2">{{
        nivelAudId != null
          ? "Editar nivel de auditoría"
          : "Crear nivel de auditoría"
      }}</v-card-title>
      <v-card-text class="py-0">
        <!-- Formulario -->
        <v-form ref="form" v-model="isFormValid">
          <v-row>
            <!-- Nombre del nivel -->
            <v-col cols="12" md="12" sm="6" class="py-1">
              <v-text-field
                v-model="nombre"
                label="Nombre del Nivel"
                hide-details="auto"
                autocomplete="off"
                outlined
                dense
                :rules="rules.required.concat([rules.requiredTrim(nombre)])"
              ></v-text-field>
            </v-col>

            <!-- Niveles destino -->
            <v-col cols="12" md="12" sm="6" class="py-1">
              <v-select
                v-model="nivelesDestinoSelected"
                :items="nivelesDestino"
                label="Posibles niveles destino"
                multiple
                outlined
                hide-details="auto"
                dense
                :rules="rules.required"
                item-text="nombre"
                item-value="nivelAudId"
              ></v-select>
            </v-col>

            <!-- Usuarios restringidos a este Nivel -->
            <v-col cols="12" md="11" sm="5" class="py-1">
              <v-autocomplete
                v-model="usuariosRestringidos"
                :items="usuarios"
                label="Usuarios restringidos a este Nivel"
                multiple
                hide-details="auto"
                outlined
                dense
                item-text="usuario"
                item-value="idUsuario"
                small-chips
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="usuariosToggle()">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          usuariosRestringidos.length > 0 ? 'primary' : ''
                        "
                      >
                        {{ multiselectIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip x-small v-if="index === 0" >
                    <span>{{ item.usuario }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ usuariosRestringidos.length - 1 }} otros)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
             <v-col cols="1" md="1" class="p-0 pt-3">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    size="1.4rem"
                    class="pt-1"
                    >{{ infoIcon }}</v-icon
                  >
                </template>
                <span
                  >Se muestran los usuarios que tienen acceso a la opción 'Auditoría médica'.</span
                >
              </v-tooltip>
            </v-col>
            <!-- Switch Puede Autorizar -->
            <v-col cols="5" md="5" sm="5" class="py-0 pl-4">
              <v-switch
                class="p-0"
                v-model="puedeAutorizar"
                hide-details="auto"
                label="Puede autorizar"
              ></v-switch>
            </v-col>
            <v-col cols="1" md="1" class="p-0 pt-3">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    size="1.4rem"
                    class=" pt-1 "
                    >{{ infoIcon }}</v-icon
                  >
                </template>
                <span
                  >Permite habilitar o deshabilitar el botón Autorizar en las
                  solicitudes que estén en este nivel.</span
                >
              </v-tooltip>
            </v-col>
            <!-- Switch Es Nivel Inicial -->
            <v-col cols="6" sm="6" class="py-0 pl-5">
              <v-switch
                class="p-0"
                hide-details="auto"
                v-model="esInicial"
                label="Es nivel inicial"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <!-- Botones de acción -->
      <v-card-actions class="py-2">
        <v-spacer></v-spacer>
        <v-btn outlined @click="cancelar()">Cancelar</v-btn>
        <v-btn :disabled="!isFormValid" color="primary" @click="guardarNivel">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditNivelAuditoria",
  directives: { mask },
  props: {
    nivelAuditoriaItemProp: {
      type: Object,
      require: false,
      default: null
    }
  },
  data() {
    return {
      rules: rules,
      infoIcon: enums.icons.SNB_INFO,
      nivelesDestino: [],
      usuarios: [],
      nivelAudId: null,
      nombre: "",
      puedeAutorizar: false,
      nivelesDestino: [],
      nivelesDestinoSelected: [],
      usuariosRestringidos: [],
      esInicial: false,
      isFormValid: false // Controla si el formulario es válido
    };
  },
  created() {
    if (this.nivelAuditoriaItemProp != null) {
      this.setDatosNivelAuditoriaEdit(this.nivelAuditoriaItemProp);
    }
    this.setSelects();
  },
  computed: {
    multiselectIcon() {
      if (this.selectAllUsuarios) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllUsuarios() {
      return (
        this.usuariosRestringidos &&
        this.usuariosRestringidos.length === this.usuarios.length
      );
    }
  },
  methods: {
    ...mapActions({
      saveNivelAuditoria: "prestaciones/saveNivelAuditoria",
      deleteNivelAuditoria: "prestaciones/deleteNivelAuditoria",
      getUsuariosNivelesAuditoria: "prestaciones/getUsuariosNivelesAuditoria",
      getNivelesAuditoriaConExclusion:
        "prestaciones/getNivelesAuditoriaConExclusion",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const usuariosResponse = await this.getUsuariosNivelesAuditoria();
      if (usuariosResponse != null) {
        this.usuarios = usuariosResponse;
      }
      const nivelesResponse = await this.getNivelesAuditoriaConExclusion(
        this.nivelAudId ?? 0
      );
      if (nivelesResponse != null) {
        this.nivelesDestino = nivelesResponse;
      }
    },
    usuariosToggle() {
      this.$nextTick(() => {
        if (this.selectAllUsuarios) {
          this.usuariosRestringidos = [];
        } else {
          this.usuariosRestringidos = this.usuarios.map(x => x.idUsuario);
        }
      });
    },
    setDatosNivelAuditoriaEdit(item) {
      this.nivelAudId = item.nivelAudId;
      this.nombre = item.nombre;
      this.esInicial = item.esInicial;
      this.puedeAutorizar = item.verBtnAutoriza;
      this.nivelesDestinoSelected = item.listaDestino;
      this.usuariosRestringidos = item.listaUsuario;
    },

    async guardarNivel() {
      const data = {
        nivelAudId: this.nivelAudId ?? 0,
        nombre: this.nombre,
        verBtnAutoriza: this.puedeAutorizar,
        esInicial: this.esInicial,
        listaDestino: this.nivelesDestinoSelected,
        listaUsuario: this.usuariosRestringidos
      };
      const response = await this.saveNivelAuditoria(data);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.cancelar();
      }
    },
    cancelar() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped></style>
